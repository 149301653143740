import { noteService } from '@/services';

function compareKeys(a, b) {
  for (const key of Object.keys(a)) {
    if (key !== 'author' &&
        key !== 'authorRef' &&
        key !== 'modified' &&
        key !== 'created' &&
        a[key] !== b[key]) {
      return true;
    }
  }
  return false;
}

export async function persistNotes(holderId, originalNotes, changedNotes) {
  const toAddList = [];
  const toUpdateList = [];

  const orgNotes = originalNotes;
  const notes = changedNotes;
  const unchangedIds = [];
  for(let i = 0, len = notes.length; i < len; i++) {
    //Keep only the needed properties.
    const note = { };
    // author, authorRef, modified, created
    for (const key of Object.keys(changedNotes[i])) {
      if (key !== 'author' &&
          key !== 'authorRef' &&
          key !== 'modified' &&
          key !== 'created') {
        note[key] = changedNotes[i][key];
      }
    }
    
    const index = orgNotes.findIndex(j => j.uuId === note.uuId);
    if(index == -1) {
      toAddList.push(note);
    } else {
      const originNote = orgNotes[index];
      if(compareKeys(note, originNote)) {
        toUpdateList.push(note);
      } else {
        unchangedIds.push(note.uuId);
      }
    }
  }

  const toUpdateIds = toUpdateList.map(i => i.uuId);
  const toRemoveList = orgNotes.filter(i => !toUpdateIds.includes(i.uuId) && !unchangedIds.includes(i.uuId));

  const service = noteService;
  const errors = []
  const errorCodes = []
  //To add new notes if there is any.
  if(toAddList.length > 0) {
    toAddList.reverse(); //Reverse list so that the new notes are created in right order
    let { failed=null, hasError=false, errorCode=null } = await service.create(toAddList, holderId)
    .then(response => {
      if(207 == response.status) {
        const list = response.data[response.data.jobCase];
        const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
        return { hasError: true, failed: toAddList.filter(i => failIds.includes(i.uuId)), errorCode: 207 };
      }
      return {};
    })
    .catch(e => {
      return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
    });
    
    if(failed != null && failed.length > 0) {
      for(let i = 0, len = failed.length; i < len; i++) {
        errors.push({ key: 'comment.error.failed_to_create_with_arg', args: [__truncateText(failed[i].identifier != null? failed[i].identifier : failed[i].text, 50)] });
      }
    } else if (hasError) {
      errors.push({ key: toAddList.length > 1? 'comment.error.failed_to_create_plural': 'comment.error.failed_to_create' })
      errorCodes.push(errorCode)
    }
  }

  //To update existing notes if there is any.
  if(toUpdateList.length > 0) {
    let{ failed=null, hasError=false, errorCode=null } = await service.update(toUpdateList)
    .then(response => {
      if(207 == response.status) {
        const list = response.data[response.data.jobCase];
        const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
        return { hasError: true, failed: toUpdateList.filter(i => failIds.includes(i.uuId)), errorCode: 207 };
      }
      return {}
    })
    .catch(e => {
      return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
    });
    
    if(failed != null && failed.length > 0) {
      for(let i = 0, len = failed.length; i < len; i++) {
        errors.push({ key: 'comment.error.failed_to_update_with_arg', args: [__truncateText(failed[i].identifier != null? failed[i].identifier : failed[i].text, 50)] });
      }
    } else if (hasError) {
      errors.push({ key: toUpdateList.length > 1? 'comment.error.failed_to_update_plural': 'comment.error.failed_to_update' })
      errorCodes.push(errorCode)
    }
  }

  //Try remove notes which user wants to remove if there is any
  if(toRemoveList.length > 0) {
    let { failed=null, hasError=false, errorCode=null } = await service.remove(toRemoveList)
    .then(response => {
      if(207 == response.status) {
        const list = response.data[response.data.jobCase];
        const failIds = list.filter(i => i.clue !== 'OK').map(i => i.args[0]);
        return { hasError: true, failed: toRemoveList.filter(i => failIds.includes(i.uuId)), errorCode: 207 };
      }
      return {};
    })
    .catch(e => {
      return { hasError: true, errorCode: e != null && e.response != null? e.response.status : null }
    });
   
    if(failed != null && failed.length > 0) {
      for(let i = 0, len = failed.length; i < len; i++) {
        errors.push({ key: 'comment.error.failed_to_delete_with_arg', args: [__truncateText(failed[i].identifier != null? failed[i].identifier : failed[i].text, 50)] });
      }
    } else if (hasError) {
      errors.push({ key: toRemoveList.length > 1? 'comment.error.failed_to_delete_plural': 'comment.error.failed_to_delete' });
      errorCodes.push(errorCode)
    }
  }

  return { errors, errorCodes };
}

function __truncateText({ value=null, maxLength=10, suffix='...' } = {}) {
  let _maxLength = 10;
  if (!isNaN(maxLength) && Number.parseInt(maxLength) > _maxLength) {
    _maxLength = Number.parseInt(maxLength);
  }

  let _suffix = '...';
  if (suffix != null && suffix.trim().length > 0) {
    _suffix = suffix;
  }

  if (value == null) {
    return value;
  }

  //Defensive code: reset to default value when suffix's length is greater than or equal to maxLength's.
  if (_maxLength - _suffix <= 0) {
    _maxLength = 10;
    _suffix = '...';
  }

  const actualMax = _maxLength - _suffix;

  let _val = value.trim();
  if (_val.length <= actualMax) {
    return _val;
  }

  return _val.substring(0, actualMax) + _suffix;
}

export function hasNotesChanged(originalNotes, notes, customFields=[]) {
  const _orgNotes = originalNotes;
  const _notes = notes;

  if (notes.length != originalNotes.length) {
    return true;
  }

  let hasChanged = false;
  for(let i = 0, len = _notes.length; i < len; i++) {
    const note = _notes[i];
    const index = _orgNotes.findIndex(j => j.uuId === note.uuId);
    if(index == -1) {
      hasChanged = true;
      break;
    } else {
      const originNote = _orgNotes[index];
      hasChanged = originNote.text !== note.text || originNote.identifier != note.identifier;
      if (hasChanged) {
        break;
      }
      for (const f of customFields) {
        if (Object.hasOwn(originNote, f.name) && Object.hasOwn(note, f.name)) {
          if (originNote[f.name] == null && note[f.name] == null) {
            continue;
          } else if ((originNote[f.name] != null && note[f.name] == null) || (originNote[f.name] == null && note[f.name] != null)) {
            hasChanged = true;
            break;
          } else if (!f.type.startsWith('Enum') &&  originNote[f.name] != note[f.name]) {
            hasChanged = true;
            break;
          } else if (f.type.startsWith('Enum')) {
            if (originNote[f.name].length != note[f.name].length) {
              hasChanged = true;
              break;
            }
            for (const v of originNote[f.name]) {
              if (!note[f.name].includes(v)) {
                hasChanged = true;
                break;
              }
            }
            if (hasChanged) {
              break;
            }
          }
        } else if (Object.hasOwn(originNote, f.name) || Object.hasOwn(note, f.name)) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        break;
      }
    }
  }
  
  return hasChanged;
}